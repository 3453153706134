export const LIST_FEATURES = [
  { bold: 'Send photos.', plain: ' By email or mobile app anytime from anywhere.' },
  { bold: 'Online access.', plain: ' View, download & share on desktop & mobile.' },
  { bold: 'Cloud backup.', plain: ' All your photos backed up forever.' },
  { bold: 'Remote settings.', plain: ' Change your Frame’s settings from the app.' },
  { bold: 'Albums.', plain: ' Create, edit, and view photo albums.' },
  { bold: 'Slideshow mirroring.', plain: ' View your photos on any screen.' },
  { bold: 'Doodles.', plain: ` Create a doodle and replay it on your device.` },
]

export const CALENDAR_LIST_FEATURES = [
  { plain: 'Email photos to your Calendar or upload them in the Skylight app.' },
  { plain: 'Watch them appear on your photo slideshow instantly.' },
  { plain: 'Simply tap on the screensaver to view your schedule.' },
]
