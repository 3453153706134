import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import { PLUS_FEATURES } from './constants'
import {
  PlusFeatureContainer,
  Container,
  Header,
  TwoColumnGrid,
  ImageContainer,
  TextContainer,
  Title,
  Text,
  Disclosure,
} from './styles'

const PlusFeatures = ({ title }) => {
  const { t } = useTranslation('common')
  const {
    albums,
    cloud,
    doodle,
    online,
    remote,
    slideshow,
    albumsMobile,
    cloudMobile,
    doodleMobile,
    onlineMobile,
    remoteMobile,
    slideshowMobile,
  } = useStaticQuery(graphql`
    query PlusFeaturesQuery {
      albums: file(relativePath: { eq: "plus/albums.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      cloud: file(relativePath: { eq: "plus/cloud.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      doodle: file(relativePath: { eq: "plus/doodle.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      online: file(relativePath: { eq: "plus/online.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      remote: file(relativePath: { eq: "plus/remote.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      slideshow: file(relativePath: { eq: "plus/slideshow.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      albumsMobile: file(relativePath: { eq: "plus/albums-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      cloudMobile: file(relativePath: { eq: "plus/cloud-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      doodleMobile: file(relativePath: { eq: "plus/doodle-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      onlineMobile: file(relativePath: { eq: "plus/online-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      remoteMobile: file(relativePath: { eq: "plus/remote-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      slideshowMobile: file(relativePath: { eq: "plus/slideshow-mobile.webp" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  const PLUS_IMAGES = [online, cloud, remote, albums, slideshow, doodle]
  const MOBILE_PLUS_IMAGES = [
    onlineMobile,
    cloudMobile,
    remoteMobile,
    albumsMobile,
    slideshowMobile,
    doodleMobile,
  ]
  return (
    <PlusFeatureContainer>
      <Container>
        <Header>{t(title || 'Designed to Delight')}</Header>
        {PLUS_FEATURES.map((feature, i) => (
          <TwoColumnGrid key={i}>
            <ImageContainer>
              <GatsbyImage
                image={PLUS_IMAGES[i].childImageSharp.gatsbyImageData}
                alt={`${feature.title} Plus Feature`}
              />
            </ImageContainer>
            <ImageContainer mobile>
              <GatsbyImage
                image={MOBILE_PLUS_IMAGES[i].childImageSharp.gatsbyImageData}
                alt={`${feature.title} Plus Feature`}
              />
            </ImageContainer>
            <TextContainer>
              <Title>
                <Trans>{feature.title}</Trans>
              </Title>
              <Text>
                <Trans>{feature.text}</Trans>
              </Text>
            </TextContainer>
          </TwoColumnGrid>
        ))}
      </Container>
      <Disclosure>
        Individual Plus subscriptions are excluded from marketing promotions and discounts.
      </Disclosure>
    </PlusFeatureContainer>
  )
}

PlusFeatures.propTypes = {
  title: PropTypes.string,
}

export default PlusFeatures
