import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation, Trans } from 'gatsby-plugin-react-i18next'

import GetPlusButton from 'src/components/GetPlusButton'
import QueryParams from 'src/utils/QueryParams'
import Analytics from 'src/utils/Analytics'
import StoreContext from 'src/context/StoreContext'

import theme from 'src/utils/styles/theme'

import {
  MediumHeader,
  MediumText,
  ColoredText,
  GridLeft,
  GridRight,
} from 'src/utils/styles/global-styles'

import { PRODUCTS } from 'src/components/AddAndRedirect/constants'

import {
  TrialEnding,
  TierBox,
  TierStripe,
  Recommended,
  List,
  ListHeader,
  ListSubHeader,
  ListHeaderContainer,
  BasicListItem,
  PlusListItem,
  ListHeaderText,
  GetBasicButton,
  ButtonContainer,
  Header,
  Section,
  Container,
  Line,
  TextGray,
  GridContainer,
} from './styles'

import { LIST_FEATURES } from './constants'

class BasicVsPlus extends React.Component {
  constructor(props) {
    super(props)
    this.state = { trialDaysRemaining: null }
  }

  componentDidMount() {
    const queryParams = QueryParams.from(window)
    if (queryParams.trial_days_remaining) {
      this.setState({ trialDaysRemaining: parseInt(queryParams.trial_days_remaining) })
    }
    if (queryParams.async_frame_add) {
      const { addToCart } = this.context
      const { eventLabel, variantId } = PRODUCTS.frame
      Analytics.track(eventLabel)
      addToCart([{ variantId, quantity: 1 }])
    }
  }

  render() {
    const { trialDaysRemaining } = this.state
    const { product, t } = this.props
    const discounted = product.handle === 'skylight-plus-limited-time-50-off'

    const { price } = product.variants[0]
    const dayOrDays = trialDaysRemaining === 1 ? 'day' : 'days'
    return (
      <Section>
        <Container>
          <Header>
            <Trans>Customize Your Skylight Experience</Trans>
          </Header>
          {trialDaysRemaining !== null && (
            <TrialEnding>
              <MediumHeader>
                <Trans
                  i18nKey="Your trial ends in x days"
                  values={{
                    trialDaysRemaining,
                    dayOrDays,
                  }}
                  components={{ bold: <b /> }}
                >
                  Your trial ends in{' '}
                  <b>
                    {{ trialDaysRemaining }} {{ dayOrDays }}
                  </b>
                  .
                </Trans>
              </MediumHeader>
              <MediumText>
                <Trans>
                  Frame Plus allows you to send videos to your frame, add captions to photos, and
                  manage them through our Mobile App and Cloud Portal. One Frame Plus account can be
                  used with all your frames, and by everyone in the family!
                </Trans>
              </MediumText>
              <MediumText>
                <Trans
                  i18nKey={
                    "At the end of your trial, you will lose access to these features. Upgrade before it's too late!"
                  }
                  components={{ bold: <b /> }}
                >
                  At the end of your trial, you will lose access to these features.{' '}
                  <b>Upgrade before it's too late!</b>
                </Trans>
              </MediumText>
            </TrialEnding>
          )}
          <GridContainer>
            <GridLeft>
              <TierBox color={theme.grayDark} discounted={discounted}>
                <TierStripe color={theme.grayDark}>
                  <Recommended> &nbsp;</Recommended>
                </TierStripe>
                <ListHeaderContainer>
                  <ListHeader color={theme.grayDark}>
                    <Trans>Basic</Trans>
                  </ListHeader>
                  <ListSubHeader color={theme.grayDark}>
                    <Trans>Free</Trans>
                  </ListSubHeader>
                  <ListHeaderText hideOnMobile> &nbsp;</ListHeaderText>
                  {discounted && (
                    <ColoredText hideOnMobile display="block">
                      &nbsp;
                    </ColoredText>
                  )}
                </ListHeaderContainer>
                <List className="basic">
                  {LIST_FEATURES.map((feature, idx) => (
                    <React.Fragment key={`basic-feature-${idx}`}>
                      <BasicListItem>
                        <Trans i18nKey={feature.bold} components={{ span: <span /> }}>
                          <span>{feature.bold}</span> <span>{feature.plain}</span>
                        </Trans>
                      </BasicListItem>
                      <Line />
                    </React.Fragment>
                  ))}
                </List>
                <ButtonContainer>
                  {trialDaysRemaining === null && (
                    <GetBasicButton to="/cart/" color={theme.grayDark}>
                      <Trans>Get Basic</Trans>
                    </GetBasicButton>
                  )}
                </ButtonContainer>
              </TierBox>
            </GridLeft>

            <GridRight>
              <TierBox color={theme.blue} discounted={discounted}>
                <TierStripe color={theme.blueDark}>
                  <Recommended>
                    <Trans>Most popular</Trans>
                  </Recommended>
                </TierStripe>
                <ListHeaderContainer>
                  {discounted ? (
                    <>
                      <ListHeader color={theme.grayDark}>Plus</ListHeader>
                      <ListSubHeader color={theme.blueDark}>
                        <ColoredText color={theme.red}>
                          <Trans>50% off! </Trans>
                        </ColoredText>
                        <Trans
                          i18nKey="discount for the first year"
                          values={{
                            price,
                          }}
                        >
                          ${price} for the first year
                        </Trans>
                      </ListSubHeader>
                      <ColoredText color={theme.blueDark} display="block">
                        <Trans
                          i18nKey="price after first year"
                          values={{
                            parsedPrice: parseInt(parseFloat(price) * 2),
                          }}
                        >
                          ${parseInt(parseFloat(price) * 2)}/year after that
                        </Trans>
                      </ColoredText>
                      <ListHeaderText>Cancel any time</ListHeaderText>
                    </>
                  ) : (
                    <>
                      <ListHeader color={theme.grayDark}>Plus</ListHeader>
                      <ListSubHeader color={theme.grayDark}>
                        <Trans
                          i18nKey="price per year"
                          values={{
                            parsedPrice: parseInt(price),
                          }}
                        >
                          ${{ parsedPrice: parseInt(price) }}/year
                        </Trans>
                      </ListSubHeader>
                      <ListHeaderText>
                        <Trans>Cancel any time</Trans>
                      </ListHeaderText>
                    </>
                  )}
                </ListHeaderContainer>
                <List>
                  {LIST_FEATURES.map((feature, idx) => (
                    <span key={`plus-feature-${idx}`}>
                      <PlusListItem>
                        <span>{`${t(`${feature.bold}-plus`)}`}</span>{' '}
                        {feature.plain ? (
                          <TextGray>{`${t(`${feature.plain}-plus`)}`}</TextGray>
                        ) : null}
                      </PlusListItem>
                      <Line />
                    </span>
                  ))}
                </List>
                <ButtonContainer>
                  <GetPlusButton product={product} />
                </ButtonContainer>
              </TierBox>
            </GridRight>
          </GridContainer>
        </Container>
      </Section>
    )
  }
}

BasicVsPlus.contextType = StoreContext

BasicVsPlus.propTypes = {
  product: PropTypes.object.isRequired,
  t: PropTypes.func,
}

export default withTranslation()(BasicVsPlus)
